.tagify {
	@include border-radius(5px);
    width: 95%;
    margin: 0 auto;
    padding: .5rem;

    tag > div {
		border: 1px solid #bcd8f3;
		background: #e5f1fc;
        color: #666;
        font-size: .95rem;
    }
		
    x {
        color: #666;
    }
}