// Modal content

.modal_overlay {
    display:none;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:url(#{$img_path}/modal-bg.png);
    text-align:center;
    z-index:2000;
    opacity:.9;
}

.modal_psend {
    display:none;
    position: fixed;
    top: 50%;
    left: 50%;
    float: left;
    z-index:5000;

    width:400px;
    height:340px;
    margin:-170px 0 0 -200px;
    background:white;
    color:black;

    @include border-radius(7px);
    @include box-shadow(0 0 23px, rgba(0, 0, 0, .3));
}

.modal_content {
    margin:15px;
}

/*
.loading-img {
    text-align:center;
}

.modal_content p.loading-img {
    margin:40px auto;
    text-align:center;
}
*/

.modal_content iframe {
    display:none;
}

.modal_title {
    $distance: 10px;

    text-align:left;
    position:relative;
    padding:$distance;
    background:#eee;
    background:-moz-linear-gradient(top , #f5f5f5, #eee)!important;
    background:-webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#eee))!important;
    color:#888;

    -webkit-border-top-left-radius: 7px;
    -webkit-border-top-right-radius: 7px;
    -moz-border-radius-topleft: 7px;
    -moz-border-radius-topright: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    a {
        position:absolute;
        display:inline-block;
        top:$distance;
        right:$distance;
    }
}

.modal_close {
    color:#888;
    font-size:2em;
}