@import "partials/variables";
@import "partials/mixins";
@import "partials/footable";
@import "partials/chosen";
@import "partials/tagsinput";
@import "partials/override";

// Backend and default theme shared styles

:focus { outline:none !important; }
::-moz-focus-inner { border:0 !important; }

strong,
.bold
{
    font-weight:bold;
}

em {
    font-style:italic;
}

p {
    margin-bottom:1.2em;
    line-height:1.5em;
}

a img {	border:none; }

.external_link:before {
    content:'\e144';
    margin-right:3px;
    font-family:"Glyphicons Halflings";
    line-height:1px;
    color:black;
}

.clear { clear:both; }

.margin_0 {
    margin:0;
}

html,
body
{
    background:$body_background;
    color:$main_text;
    font-family:$font_body;
    font-size:.9em;
    line-height:1.2em;
    height:100%;
    position:relative;
    padding:0;
    margin:0;
}

body.login {
    height:auto;
}

.none {
    display: none;
}

.container-custom {
    padding-top:$padding_top_main;
    min-height:100%;
    position:relative;
    width:100%;
}

.main_side_menu {
    padding-top:$padding_top_main;
    width:$side_menu_width;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    background:$side_menu_background;
    border-right:1px solid #ccc;

    ul {
        list-style: none;
    }
}


.main_content {
    margin-left:$side_menu_width;
    padding:10px 20px;
}

.menu_contracted {
    .main_side_menu {
        width:$side_menu_width_contracted;
    }

    .main_content {
        margin-left:$side_menu_width_contracted;
    }
}

// Backend only styles

.backend {
    a {
        text-decoration:none;

        &:hover,
        &:focus
        {
            text-decoration:none;
        }
    }

    .clear {
        clear:both;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight:normal;
        font-family: $font_heading;
    }

        h1 {
            color:white;
            font-size:32px;
            line-height:32px;
            margin:15px 0;
        }

        h2 {
            color:#333333;
            font-size:27px;
            line-height:29px;
            margin:0 0 35px;
            letter-spacing:-1px;
        }

        h3 {
            font-size:17px;
            line-height:19px;
            padding:0;
            font-weight:900 !important;
            margin-bottom:20px;
            text-transform:uppercase;
        }

        h4 {
            font-size:13px;
            margin-bottom:20px;
            line-height:18px;
        }
        
        h5 {
            font-size:17px;
            margin-bottom:20px;
            line-height:18px;
            
            i {
                margin-right: 8px;
            }
        }

    .system_msg {
        padding:10px;
        background:#FFFBE4;
        border-bottom:1px solid #CCCCCC;
        margin-bottom: 20px;

        p {
            margin:0;
        }
    }

    #main {
        padding:30px;
    }

    // Update available message bar

    .update_msg {
        .buttons {
            padding:0 0 0 25px;
            display:inline-block;
            zoom:1;
            margin-top:3px;
        }

        ul {
            text-align:right;

            li {
                display:inline-block;
                margin:0;
                font-size:0;
            }

            .update_icon {
                display:inline-block;
                background:black;
                color:white;
                text-align:center;
                padding:4px;
                font-size:20px;
                margin:0 2px;
                @include border-radius(50%);
            }

            .update_icon_status_0 {
                @include opacity(.4);
            }
        }

    }

    &.default-template .update_msg {
        display:none !important;
    }

    #donations_message {
        background:#0094bb ;
        color:#fff;
        @include border-radius(2px);
        margin:0 0 20px;
        padding:30px;
        font-size:1.1em;

        h3 {
            margin-top:0;
            font-size:1.5em;
        }

        p {
            margin-bottom:0;
        }

        a {
            color:white;
            font-weight:bold;
        }
    }

    // Header

    .branding_unlogged {
        text-align:center;
        margin:20px auto;

        img, svg {
            height:auto;
            width:100%;
            max-width:250px;
        }
    }

    #login_logo {
        margin:15px auto;
        text-align:center;
    }

    .header_unlogged {
        clear:both;

        .navbar-header {
            display:block;
            width:100%;
            padding:0;

            span {
                display:block;
                width:100%;
            }
        }
    }

    #install_form {
        margin-top:30px;
    }

    #install_extra {
        margin-top:30px;
        font-weight:bold;
        font-size:11px;
        color:#0099CC;
    }

    #install_inside {
        width:400px;
        margin:20px auto 0;
    }

    // Home widgets

    .widget {
        background:white;
        display:block;
        border:1px solid #ccc;
        width:100%;
        margin-bottom:30px;

        @include border-radius(2px);
        /*@include box-shadow(3px 3px 5px, #dedede);*/

        .widget_int {
            padding:15px;
        }

        h4 {
            text-align:left;
            padding:13px;
            font-size:16px;
            margin:0 !important;
            color:$widget_title_color;
            background: $widget_title_background;
            border-bottom:1px solid #dedede;
            @include border-radius(2px 2px 0 0);
        }

        &:hover h4 {
            background: $widget_hover_background;
        }

    }

    // Counters
    .widget_counters {
        ul {
            list-style: none;
            margin: 0 0 30px;
            padding: 0;
            display: flex;

            li {
                flex: 1;
                margin: 0 15px;
                background: red;
                color: white;
                padding: 20px;
                position: relative;

                &:nth-child(1) { background: #f2b705; }
                &:nth-child(2) { background: #86ae00; }
                &:nth-child(3) { background: #717bbb; }
                &:nth-child(4) { background: #de629d; }
                &:nth-child(5) { background: #0094bb; }

                h6 {
                    font-weight: bold;
                    font-size: 35px;
                }

                h5 {
                    font-weight: normal;
                    font-size: 22px;
                }

                i {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    font-size: 100px;
                    opacity: .2;
                }
            }

            @include bk(xs) {
                flex-wrap: wrap;

                li {
                    flex: 1 0 20%;
                    margin-bottom: 20px;

                    i {
                        opacity: .1;
                    }
                }
            }
        }
    }

    // Home LOG widget
    #widget_actions_log {
        #log_container {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding: 7px;
                    border-bottom: 1px solid #dedede;
                    display: flex;

                    &:nth-child(odd) {
                        background: #f6f6f6;
                    }

                    &:hover {
                        background: #fbf8e9;
                    }

                    i {
                        margin:0 1em;
                        color: #1ec4a7;
                        text-align: center;
                        font-size: 14px;
                    }

                    .date {
                        text-align: right;

                        span {
                            display: inline-block;
                            padding: 3px 0 0 0;
                            font-weight: bold;
                            line-height: 1;
                        }
                    }

                    .action {
                        flex: 2;
                        word-break: break-all;

                        .item_part1, .item_part2, .item_part4 {
                            color: $main_color;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .activities_log {
        padding:0;

        li {
            padding:7px;
            border-bottom:1px solid #EFEFEF;
            overflow:hidden;

            &:nth-child(odd) {
                background:#fafafa;
            }

            &:last-child {
                border-bottom:none !important;
            }

            .date {
                font-size:.9em;
                color:#999;
            }

            .log_ico {
                width:45px;
                float:left;
                @include opacity(.1);
            }

            &:hover .log_ico {
                @include opacity(.7);
            }

            span {
                font-weight:bold;
                color:$main_color;
            }

            span.secondary {
                color:#86AE00;
            }
        }
    }

    .view_full_log {
        text-align:right;
        padding-top:5px;
        margin-top:5px;
        border-top:1px solid #EFEFEF;
    }

    // Home NEWS widget

    .home_news {
        margin:0;
        padding:0;

        .date {
            color:#999;
            font-size:.9em;
        }

        h5 {
            margin:0 0 3px;
        }
    }

    // Home graphs
    .stats_change_days {
        margin-bottom:1.5em;
        display:inline-block;
        zoom:1;
    }

    .graph_legend {
        float:none;
        clear:both;
        display:block;
        text-align: left;
        margin: 0 0 30px;
        padding: 0;

        @include bk(md) {
            display:inline-block;
            text-align:right;
            padding-top:5px;
            zoom:1;
            margin:0 0 0 auto;
            float:right;
        }

        li {
            display:inline-block;
            margin-left:5px;
            font-size:.9em;
            padding:4px;

            &.legend_color {
                margin: 2px 5px 0 0;
            }
        }

    }

    .stats_change_data, .log_change_action {
        margin-bottom:2em;
    }

    .widget_system_info {
        .widget_int h3:first-child {
            margin-top:0;
        }

        dl {
            margin-bottom:25px;
        }

        dt {
            text-align:left;
        }

        dd {
            word-break: break-all;
        }
    }

    .loading-icon {
        text-align: center;
        margin: 30px auto;
        padding: 0;

        svg {
            width: 100px;
            height: auto;
        }
    }

    // Home statistics tooltip

    .widget_statistics {
        #stats_tooltip {
            position:absolute;
            display:none;
            background:black;
            color:#FFFFFF;
            padding:5px;
        }

        #chart_container canvas {
            display:block;
            width: 100%;
            padding: 0;
            margin: 0;
        }
    }

    // Main white content boxes

    .whitebox {
        background-color:white;
        border:1px solid #ccc;

        @include border-radius(2px);
    }

    .white-box {
        background-color:white;
        border:1px solid #ccc;

        @include border-radius(2px);

        .white-box-interior {
            padding:15px;
        }
    }

    .whiteform {
        margin:0;
        text-align:left;
        padding:20px;

        td {
            height:25px;
        }
    }

    .whitebox_text {
        padding-top:25px;
    }

    // Categories

    .categories_form {
        padding:20px;
        background:white;
        margin-bottom:40px;
        border:1px solid #ccc;
        @include border-radius(2px);
        /*@include box-shadow(3px 3px 5px, #dedede);*/

        h3 {
            margin:0 0 30px;
        }
    }

    // File management

    .file_status_visible, .account_status_active {
        color:#639822!important;
        font-weight:bold;
    }

    .file_status_hidden, .account_status_inactive {
        color:#D24244!important;
        font-weight:bold;
    }


    // VALIDATION
    .form-group {
        label.error {
            font-size:.85rem;
            color:#B94A48;
            background:#F2DEDE;
            margin:0;
            padding:.5rem;
            border:1px solid #EED3D7;
            position:relative;
            display: block;
            @include border-radius(0 0 3px 3px);
        }
    }

    .field_error {
        border:1px solid #B94A48 !important;
    }

    .field_error_msg {
        position:relative;

        ul {
            font-size:.95em;
            color:#B94A48;
            background:#F2DEDE;
            margin:0;
            padding:10px;
            border:1px solid #EED3D7;
            position:relative;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;

            li {
                padding:3px 0;

                &:last-child {
                    border-bottom:none;
                }
            }
        }

        &:after,
        &:before
        {
            bottom: 100%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none;
        }

        &:after {
            border-color: rgba(45, 237, 14, 0); border-bottom-color:#F2DEDE; border-width: 10px; right: 5%; margin-left: -10px;
        }

        &:before {
            border-color: rgba(238, 211, 215, 0); border-bottom-color:#EED3D7; border-width: 11px; right: 5%; margin-left: -11px;
        }

        .options_box & {
            margin-left:44%;
        }

        .error_no_margin & {
            margin-left:0;
        }
    }

    // Edit file information on UPLOAD

    .file_editor {
        padding-top:15px;
        margin-bottom:40px;
    }

    .edit_files {

        .edit_files_blocks {
            background:white;
            border:1px solid #ccc;
            @include border-radius(2px);
            @include box-shadow(3px 3px 5px, #dedede);
            padding:30px 15px;

            &:hover {
                border:1px solid #888;
                @include box-shadow(3px 3px 5px, #ccc);
            }
        }

        .file_data {
            h3 {
                padding-top:0;
                margin-top:0;
            }

            @include bk(xs) {
                background:white;
                margin-bottom:25px;
            }
        }

        textarea {
            height:10em!important;
        }

        .column {
            padding:0 25px;
            margin-bottom: 50px;

            @include bk(md) {
                margin-bottom: 0;
            }

            &:last-child {
                border:none;
            }
        }

        .divider {
            margin:40px auto;
        }
    }

    .file_number {
        font-family:$font_heading;
        position:relative;
        word-break:break-all;
        margin:0;
        padding:10px;
        background:#232835;
        color:white;
        font-size:18px;
        display:inline-block;
        @include border-radius(5px 5px 0 0);

        p {
            padding:0 15px;
            margin:0;
            display:inline-block;
            word-wrap:break-word;

            span {
                display:inline-block;
                color:#888;
                margin:0 15px 0 0;
            }
        }
    }

    .list_mass_members {
        margin:10px 0 30px;
        padding:0;

        a {
            margin-right:5px;

            &:last-child {
                margin-right:0;
            }
        }
    }

    // Branding

    #current_logo_h2 {
        margin:30px 0 10px;
    }

    #current_logo {
        margin:20px auto;
        text-align:center;
        padding:20px 0 0;
        overflow:hidden;

        @include bk(xs) {
            text-align:center;
        }
    }

    #current_logo_img {
        padding:20px 0;

        img, svg {
            height:auto;
            width:100%;
            max-width:300px;
        }
    }

    .preview_logo_note {
        font-size:.9em;
        margin-top:30px;
        color:#888;
    }

    #form_upload_logo {
        padding:30px 0 10px;
        border-top:1px dotted #CCCCCC;
    }

    // OPTIONS page

    .field_note {
        margin:4px 0 15px 0;
        font-size:1em;
        color:#999;
        padding-left:6px;
        font-weight:300;
        border-left:3px solid #eee;

        a {
            color:#0094bb;
            text-decoration:underline;
        }

        .box-reset-password & {
            margin-left:0;
        }
    }

    optgroup {
        padding:1em;
        border:dotted #999;
        border-width:1px 0 0 0;
    }
    
    input[type="number"].number_small {
        width: 80px;
    }

    .checkbox_options {
        width:auto !important;
    }

    .options_divide {
        clear:both;
        overflow:hidden;
        margin:25px 0;
    }

    .options_column {
        overflow:hidden;
    }

    .textarea_high {
        min-height:200px;
    }

        .options_col_left {
            width:47%;
            float:left;
        }

        .options_col_right {
            width:47%;
            float:right;
        }

    .preview_button {
        margin-top:25px;
        text-align:center;

        button {
            margin-bottom:10px;
        }
    }

    .template_selector {
        .template {
            margin-bottom:40px;
            padding:15px 0;
            background:white;
            overflow:hidden;
            position:relative;

            &.current_template {
                border:5px solid $main_color;
            }

            h4 {
                font-size:1.5em;
                color:black;
                margin-top:25px;
            }

            .info {

                h5 {
                    margin:20px 0 10px;
                    font-size:1.2em;
                }
            }

            .images {
                position:relative;

                img {
                    width:100%;
                    height:auto;
                }

                .screenshot {
                    display:block;
                }

                .cover {
                    @include opacity(0);
                    position:absolute;
                    width:100%;
                    left:0;
                    top:0;
                    height:100%;
                    transition:.3s all linear;
                }
            }

            &:hover {
                .images {
                    .cover {
                        @include opacity(1);
                    }
                }
            }

            .buttons {
                text-align:right;
            }
        }
    }

    // Recreate the dark, inverted button from Bootstrap 2

    .btn-inverse,
    .btn-inverse:focus
    {
        background:#333;
        color:white;
        border-color:#333;
    }

    .btn-inverse:hover {
        color:white;
        background:#555;
    }

    // Misc

    .button_label {
        display:none;
    }

    .td_checkbox {
        width:50px;
    }

    #uploaded_files_tbl {
        margin-bottom:70px;
    }

    th.select_buttons {
        width:220px;
    }

    .membership_toggle {
        margin-right:10px;
    }

    .membership_toggle .toggle-group { transition: left 0.1s; -webkit-transition: left 0.1s; }

    .form_results_filter {
        margin-bottom:20px;

        a {
            text-decoration:underline;
            display:inline-block;
            padding-right:5px;
            margin-right:5px;
            border-right:1px solid #dedede;

            &:last-child {
                border-right:none;
            }

            &.filter_option {
                color:#c3c3ca;
            }
        }
    }
}

// Topmost header

#header {
    a {
        transition:.2s all ease-in-out;
    }

    .navbar-header svg {
        fill:#777;
        float:left;
        margin:-4px 15px 0 0;
        width:20px;
        height:auto;

        &:hover {
            fill:#fff;
        }
    }

    &.navbar {
        margin:0;
        background:$top_bar_background;
        color:#e6e7ec;
        line-height:normal;
        min-height:30px;
        -moz-border-radius:0;
        -webkit-border-radius:0;
        border-radius:0;
        font-size:0;

        .nav {
            > li {
                margin:0;
                font-size:13px;
    
                > a {
                    color:#f0f0f0;
                    line-height:33px;
                    padding:3px 10px;
                    border-left:1px solid #474b58;
                    margin:0;
    
                    &:hover, &:focus {
                        color:#fff;
                        background:$top_bar_hover;
                    }
    
                    &.toggle_main_menu {
                        font-size:2em;
                        margin-top:3px;
                        border-left:none;
    
                        span {
                            display:none;
                        }
    
                        &:hover,
                        &:focus
                        {
                            background:none;
                        }
                    }
                }
            }

            .open > a, .nav .open > a:focus, .nav .open > a:hover {
                background: #333;
            }
        }

        .nav_account > li {
            display:inline-block;

            > a {
                color:#f0f0f0;
            }

            i {
                display: none;

                @include bk(sm) {
                    display: inline-block;
                }
            }
        }
    }

    .navbar-brand {
        height:40px;
        line-height:10px;
        color:#f0f0f0;

        &:hover,
        &:active,
        &:focus
        {
            text-decoration:none;
        }
    }

    #header_welcome {
        margin-right:10px;
        color:#ccc;
    }
}

.dashboard_contents {
    padding-top:10px;
}

// Main menu

.main_menu {
    margin:0;
    padding:0;

    a {
        color:$side_menu_color;
        font-size:1.1em;
    }

    > li {
        margin:0;
        position:relative;

        &.separator {
            border-top:1px solid $side_menu_separator_border;
            margin-bottom:15px;
        }

        &.has_dropdown::after {
            font-family: FontAwesome;
            content:'\f0d7';
            position:absolute;
            top:1em;
            right:1em;
            color:$side_menu_color;
        }

        > a {
            display:block;
            padding:1em 1em;

            i {
                margin-right:1em;
            }
        }

        > ul {
            border-top:1px solid $side_menu_subtle;
            padding:1em 0 1em 28px;
            font-size:.9em;
            display:none;
            background:$side_menu_dark;

            > li {
                position:relative;

                ::before {
                    content:'';
                    background-color:$side_menu_subtle;
                    position:absolute;
                    top:11px;
                    left:-3px;
                    font-size:.8em;
                    width:8px;
                    height:8px;
                    transition:.2s all ease-in-out;
                    transform:scale(1);
                    @include border-radius(50%);
                }

                ::after {
                    content:'';
                    position:absolute;
                    top:0;
                    left:0;
                    bottom:0;
                    border-left:2px solid $side_menu_subtle;
                    transition:.2s all ease-in-out;
                }

                a {
                    display:block;
                    padding:.5em 0 .5em 1.5em;
                    color:$side_menu_sub_color;
                }

                &.current_page {
                    background:$side_menu_subtle;
                }

                &:hover {
                    background:$side_menu_subtle;

                    ::before {
                        transform:scale(1.5);
                    }
                }
            }
        }

        &.current_page,
        &.current_nav
        {
            background:$side_menu_dark;
            border-right:7px solid $main_color;

            > a {
                color:$side_menu_color;
            }
        }
    }

}

// Contracted version
.menu_contracted {
    .main_menu {
        > li {
            text-align:center;
            position:relative;
            margin-bottom:15px;

            &.has_dropdown::after {
                display:none;
            }

            > a {
                padding:.5em;
                font-size:.8em;
                text-align:center;

                i {
                    margin:0;
                    text-align:center;
                    font-size:2.2em;
                    color:$side_menu_con_color;
                }

                .menu_label {
                    display:none;
                }
            }

            > ul {
                position:absolute;
                left:45px;
                top:0px;
                width:200px;
                z-index:50;
                border-top:none;
                padding:0;
                font-size:.8em;
                display:none;
                border-left:7px solid $main_color;

                > li {
                    position:relative;

                    ::before {
                        display:none;
                    }

                    ::after {
                        display:none;
                    }

                    a {
                        display:block;
                        padding:1em 1em;
                        color:$side_menu_sub_color;
                        text-align:left;
                    }

                    &:hover {
                        background:$side_menu_dark;
                        background:$side_menu_subtle;

                        a {
                            color:$side_menu_sub_hover;
                        }
                    }
                }
            }

            &.current_page,
            &.current_nav {
                border-right:none;
                background:$main_color;

                > a {
                    > i {
                        color:$side_menu_con_active_color;
                    }
                }
            }
        }

    }
}

@include bk(sm) {
    // Always expand the current item on the large menu
    body:not(.menu_contracted) {
        .main_menu {
            .current_nav {
                .dropdown_content {
                    display:block;
                }
            }
        }
    }
}

// Footer

#footer {
    margin:50px 0 10px 0;
    padding:20px 0;
    border-top:1px solid #dedede;
    text-align:center;
    color:#888;

    a {
        color:#666;
    }

    .footer_links {
        ul {
            margin: 0 auto 2em;
            padding: 0;

            li {
                list-style: none;
                display: inline-block;
                margin: 0 5px;

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

// Messages

#login_error {
    margin:0 auto 20px auto;
}

#donations_message {
    a {
        text-decoration:underline;
    }

    h3 {
        border:none;
    }
}

form .alert {
    margin-top:2em;
}

// Forms

.form_fields li label {
    display:inline-block;
    width:40%;
}

.textarea_label {
    vertical-align:top;
}

.form_submit_li {
    margin-top:25px;
    text-align:center;
}

.required {
    background:url(#{$img_path}/req-text.png) no-repeat right -2px;
    padding-right: 50px;
}

li .password_toggle {
    width:257px !important;
}

li .required.password_toggle {
    width:214px !important;
}

.btn_generate_password {
    margin-top:5px;
}

#add_group_form_description {
    height:150px;
}

// Files actions

.form_actions_left {
    display:block;
    zoom:1;
    float:left;

    form {
        display:inline-block;
        zoom:1;
    }

    input[type="text"] {
        font-size:13px;
        height:30px;
        line-height:30px;
    }
}

.form_actions_right {
    display:block;
    zoom:1;
    float:right;
}

.form_actions_left select,
.form_actions_right select
{
    height:30px;
    font-size:13px;
}

.form_actions,
.form_actions_limit_results
{
    padding:0;
    margin-bottom:5px;
}

.form_count_total {
    float:left;
}

.form_actions_count {
    text-align:left;
    padding-top:15px;
    font-size:1.2em;
    overflow:hidden;
    border-top:1px dotted #ccc;
}

.form_actions {
    .form_actions_submit select {
        width:140px;
    }

    .form_actions_submit select.large_select {
        width:320px !important;
    }
}

.form_actions_limit_results {
    .txtfield {
        width:140px;
    }

    .form_actions_search_box {
        width:80px !important;
    }
}

#btn_proceed_search {
    margin-right:10px !important;
}

.form_actions_submit label {
    font-size:14px;
    margin-right:15px;
    font-weight:normal;
}

// Buttons

.close-popover {
    text-align:center;
    margin:15px auto;
}

.after_form_buttons {
    margin-top:50px;
    text-align:center;
}

.inside_form_buttons {
    margin-top:25px;
    text-align:center;
}

.whitebox .alert-info {
    margin-top:25px;
}

.alert .alert-title {
    padding-bottom:0;
    margin-bottom:8px;
    border:none;
    font-weight:bold;
    font-size:17px;
}

// Files list view (File Manager)

.file_name {
    font-weight:bold;

    a {
        text-decoration:underline;
    }
}

.thumbnail {
    padding: 0;
    margin-bottom: 0;
    @include border-radius(0);
}

.public_link_copy {
    padding:5px;
    width:100%;
    margin:10px auto !important;
}

.public_link_modal {
    .copied {
        display:none;
        padding:7px;
        margin:8px 0;
        font-weight:bold;
        color:#849F4F;
        background:#ECF5DA;
        border:1px solid #B1C786;
    }

    .copied_not {
        display:none;
        padding:7px;
        margin:8px 0;
        font-weight:bold;
        color:#B94A48;
        background:#F2DEDE;
        border:1px solid #EED3D7;
    }
}

// Misc

.forbidden {
    h2 {
        margin-top:40px;
    }
}

.pagination_wrapper {
    margin:2em auto;
}

.go_to_page #page_number {
    width:4em !important;
    text-align:center;
}

.format_url {
    word-break:break-all;
}

// Public download page
body.download {
    .file_title {
        span {
            font-size: 20px;
        }
    }

    .description {
        font-size: 15px;
        line-height: 20px;
    }

    .actions {
        margin-top: 20px;
    }
}

// Public files and groups listing
.public {
    .intro {
        margin-bottom: 20px;
    }

    .listing {
        ul {
            margin:0;
            padding: 0;

            li {
                padding: 8px 0;
                border-bottom: 1px solid #dedede;

                &:last-child {
                    border: none;
                }

                &.file:hover {
                    background: #f0f0f0;
                }

                i {
                    margin-right: .5em;
                }

                ul {
                    padding-left: 2em;
                }
            }
        }
    }
}

// Preview modal
#previewModal {
    .modal-header {
        .modal-title {
            display: inline-block;
            margin-bottom: 0;
        }
    }

    .modal-body {
        audio {
            width: 100%;
        }
    }

    .embed-responsive {
        background: black;
    }
}

// Misc Mobile only styles

@include bk(xs) {
    .main_content {
        padding:0;
    }

    .menu_hidden {
        .main_side_menu {
            display:none;
        }

        .main_content {
            margin-left:0px;
        }
    }

    .navbar-header {
        display:none;
    }

    .main_title {
        text-align:center;
        margin-bottom:20px;
    }

    #header #account {
        text-align:center;

        span {
            display:block;
            margin-bottom:8px;
        }
    }

    .form_filters {
        display:block;
        clear:both;
    }

   .form_filters select {
      max-width: 300px;
   }

   .form_actions_left,
    .form_actions_right
    {
      float: none;
   }

    .group_float {
        float:left;
        margin-right:5px;
    }
}

@import "parts/login";
@import "parts/modal";
