$body_background 					: #f4f4f4;
$top_bar_background					: #0d0f14;
$top_bar_hover 						: #232835;
$main_color 						: #004b92;
$main_lighten 						: lighten($main_color, 7%);
$main_darken 						: darken($main_color, 7%);
$seconary_color						: #333;
$main_text 							: #666;
$padding_top_main 					: 60px;

$side_menu_width 					: 220px;
$side_menu_width_contracted 		: 45px;

/** Side menu */
	/** Dark sidebar menu skin */
	$side_menu_background			: #232835;
	$side_menu_dark 				: #0d0f14;
	$side_menu_highlight 			: #373f53;
	$side_menu_subtle 				: #232835;
	$side_menu_color				: #fff;
	$side_menu_sub_color 			: #b0b3bb;
	$side_menu_sub_hover			: #fff;
	$side_menu_separator_border		: rgba(255, 255, 255, .1);

	/** Light sidebar menu skin */
	$side_menu_background			: #e0e0e2;
	$side_menu_dark 				: #d6d6d9;
	$side_menu_highlight 			: #eeeef1;
	$side_menu_subtle 				: #cbcbcd;
	$side_menu_color				: #3f3f47;
	$side_menu_sub_color 			: #3f3f47;
	$side_menu_sub_hover 			: #333339;
	$side_menu_separator_border	: none;

	/** Contracted side menu */
	$side_menu_con_color			: #222;
	$side_menu_con_active_color		: #fff;

/** Widgets */
$widget_title_color					: #42454a;
$widget_title_background			: #fff;
$widget_hover_background			: #f0f0f0;

/** Fonts */
$font_body							: "Helvetica Neue",Helvetica,Arial,sans-serif;
$font_heading						: "Open Sans", Arial, sans-serif;
$font_subheading					: $font_heading;

/** Paths */
$img_path : "../img";

/** Brands */
$brands_colors: (
    google: #DB4437,
    facebook: #3c5a99,
    twitter: #1da1f2,
    windowslive: #f25022,
    yahoo: #410093,
    openid: #f7931e
);