@font-face {
    font-family: 'footable';
    src: url('../fonts/footable.eot');
    src: url('../fonts/footable.eot?#iefix') format('embedded-opentype'), url('../fonts/footable.woff') format('woff'), url('../fonts/footable.ttf') format('truetype'), url('../fonts/footable.svg#footable') format('svg');
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: 'footable';
        src: url('../fonts/footable.svg#footable') format('svg');
        font-weight: normal;
        font-style: normal;
   }
}
.footable {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #ccc;
    font-size: 1em;
    color: #444444;
    background: #ffffff;
    margin:10px auto 15px;
}

.footable > tfoot > tr > td {
    padding:20px 0 !important;
}
.footable > thead > tr > th {
    border-left: 1px solid #fff;
    padding: 1px 4px;
    text-align: left;
    color:#333;
}
.footable > thead > tr > th,
.footable > thead > tr > td,
.footable > thead > th.active
{
    font-size: .8em;
    background-color: #E2E5E9 !important;
    vertical-align: middle;
}
.footable > thead > tr > th a,
.footable > thead > tr > td a
{
    display:inline-block;
    color:#222;
    text-decoration:none;
}
.footable > thead > tr > th.footable-sorted-asc > span.footable-sort-indicator:before {
    content: "\e013";
}
.footable > thead > tr > th.footable-sorted,
.footable > thead > tr > th.footable-sorted-asc,
.footable > thead > tr > th.footable-sorted-desc
{
    background-color: $main_color !important;
    color:white !important;
}
.footable-sorted a, .footable-sorted-asc a, .footable-sorted-desc a {
    color:white !important;
}
.footable > thead > tr > th.footable-first-column, .footable > thead > tr > td.footable-first-column {
    border-left: none;
}
.footable.breakpoint > tbody > tr.footable-row-detail {
    background: #fff;
}
.footable-row-detail-inner {
    width:100%;
    max-width:400px;
}
.footable.breakpoint > tbody > tr.footable-row-detail > .footable-row-detail-cell {
    padding:15px;
}
.footable-row-detail-name, .footable-row-detail-value {
    padding:5px 0;
    border-bottom:1px solid #dedede;
}
.footable > tbody img {
    vertical-align: middle;
}
.footable > tbody > tr:hover > td {
    background: #fbf8e9 !important;
}
.footable > tbody > tr > td {
    padding: 7px 4px;
    text-align: left;
    vertical-align: middle;
}
.footable > tbody > tr.odd > td {
    background:#F6F6F6;
}
.footable .pagination {
    margin: 20px 0;
}
.footable .pagination > ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #ffffff;
}
.footable .pagination > ul > li {
    display: inline;
}
.footable .pagination > ul > li > a, .footable .pagination > ul > li > span {
    float: left;
    padding: 4px 12px;
    line-height: 20px;
    text-decoration: none;
    border: 1px solid #cccccc;
    border-left-width: 0;
}
.footable .pagination > ul > li:first-child > a, .footable .pagination > ul > li:first-child > span {
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    border-left-width: 1px;
}
.footable .pagination > ul > li:last-child > a, .footable .pagination > ul > li:last-child > span {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px;
}
.footable .pagination > ul > li > a:hover, .footable .pagination > ul > li > a:focus, .footable .pagination > ul > .active > a, .footable .pagination > ul > .active > span {
    background-color: #eeeeee;
}
.footable .pagination > ul > .active > a, .footable .pagination > ul > .active > span {
    color: #444444;
    cursor: default;
}
.footable .pagination > ul > .disabled > span, .footable .pagination > ul > .disabled > a, .footable .pagination > ul > .disabled > a:hover, .footable .pagination > ul > .disabled > a:focus {
    color: #cccccc;
    cursor: default;
    background-color: #ebf3fc;
}
.footable .pagination.pagination-centered {
    text-align: center;
}
.footable .pagination.pagination-right {
    text-align: center;
}
.footable-odd {
    background-color: #f7f7f7;
}
